
import { DefaultTable } from 'apptimizm-ui'
import axios from '@/axios'
import { DefaultTableExposed, TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import { Ref } from 'vue'
import { AreaStatistic, AreaStatisticMeta } from './types'
import { IEmployee } from '../areas/types'
import { Employee } from '../employee/types'

interface TableFilter {
  search: Ref<string>
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
  tabValue: Ref<'id'|'tsd'>
}

export default ({ table, tableFilter, tabValue }: ITableProps) => {
  const areaStatisticMeta = new AreaStatisticMeta()

  const headers: TableHeader[] = [
    { name: '№ зоны', sort: 'serial_number', minWidth: '160px' },
    { name: 'Название зоны', sort: 'storage_name', minWidth: '400px' },
    { name: 'Кол-во записей', sort: 'counter_rows', minWidth: '160px' },
    { name: 'С. Скан', sort: 't', minWidth: '160px' },
    { name: 'С. УК', sort: 't', minWidth: '160px' },
    { name: 'А. Скан', sort: 't', minWidth: '160px' },
    { name: 'А. УК', sort: 't', minWidth: '160px' }
  ]

  const getUser = (employee: any): string => {
    if (!employee?.username) return '(Пользователь удалён)'
    else if (employee.is_deleted) return `${employee.username} (Удалён)`
    else return employee.username
  }

  const line = (item: AreaStatistic) => {
    return (
      <tr>
        <td>{item.serialNumber}</td>
        <td>{item.title}</td>
        <td>{item.counterRows}</td>
        <td>{item.counterScan.map(x => tabValue.value === 'id' ? <p>{getUser(x.employee)}</p> : <p>{x.employee?.terminal?.number}</p>)}</td>
        <td>{item.counterController.map(x => tabValue.value === 'id' ? <p>{getUser(x.employee)}</p> : <p>{x.employee?.terminal?.number}</p>)}</td>
        <td>{item.auditorScan.map(x => tabValue.value === 'id' ? <p>{getUser(x.employee)}</p> : <p>{x.employee?.terminal?.number}</p>)}</td>
        <td>{item.auditorController.map(x => tabValue.value === 'id' ? <p>{getUser(x.employee)}</p> : <p>{x.employee?.terminal?.number}</p>)}</td>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={areaStatisticMeta.endpoint}
        defaultFilter={tableFilter.tableParams}
        itemConverter={(v: AreaStatistic) => AreaStatisticMeta.load(v)}
        ref={table}
        line={line}
        headers={headers}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        scrollPagination={true}
        perPage={20}
      />
    </div>
  )
}
