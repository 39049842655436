import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'
import { Employee } from '../employee/types'

interface IStatus {
  name: string
  value: 'ready'|'not_ready'
}

interface RolesInterface {
  id: string
  employee: Employee
}

class AreaStatistic extends Entity {
  id: string = ''
  serialNumber: number = 0
  title: string = ''
  storageName: string = ''
  barcodeAmount: number = 0
  status: IStatus = { name: '', value: 'not_ready' }
  isAutoAssignment: boolean = false
  counterRows: number = 0
  counterScan: RolesInterface[] = []
  counterController: RolesInterface[] = []
  auditorScan: RolesInterface[] = []
  auditorController: RolesInterface[] = []
  storage: RolesInterface[] = []
}

class AreaStatisticMeta extends EntityMeta<AreaStatistic> {
  endpoint: string = host + '/api/v1/zone/statistics'

  fields = {
    id: { type: String },
    serialNumber: { type: Number, backendKey: 'serial_number' },
    title: { type: String },
    storageName: { type: String, backendKey: 'storage_name' },
    barcodeAmount: { type: Number, backendKey: 'barcode_amount' },
    status: { type: Object },
    isAutoAssignment: { type: Boolean, backendKey: 'is_auto_assignment' },
    counterRows: { type: Number, backendKey: 'counter_rows' },
    counterScan: { type: Array, backendKey: 'counter_scan' },
    counterController: { type: Array, backendKey: 'controller' },
    auditorScan: { type: Array, backendKey: 'auditor' },
    auditorController: { type: Array, backendKey: 'auditor_controller' },
    storage: { type: Array }
  }

  load (data: any): AreaStatistic {
    const result = super.load(data)
    if (data.counter_rows === 0) result.counterRows = 0
    result.counterScan = [...data.counter_scan]
    result.counterController = [...data.controller]
    result.auditorScan = [...data.auditor]
    result.auditorController = [...data.auditor_controller]
    result.storage = [...data.storage]

    return result
  }
}

export {
  AreaStatistic,
  AreaStatisticMeta
}
